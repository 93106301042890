import Resource from 'components/global/Resource'
import { Button, LanguageToggle } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import SubNav from 'components/SubNav'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { H1, H2, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import GetStartedCTA from 'components/forms/GetStartedCTA'
import SectionTitle from 'components/global/SectionTitle'
import JourneyOfABook from 'components/cta/JourneyOfABook'
import OverlayVideo from 'components/overlay-video'

export default function LibrariesPage() {
	const [language, setLanguage] = useState<'english' | 'spanish'>('english')

	const collections = [
		{
			id: 'wide-reading',
			name: 'Wide Reading',
			text: (
				<span>
					Crafted to draw <span className="font-semibold italic text-gray-800">every</span> student into the world of reading, our collections ensure a book for every
					taste. Each basket <span className="font-semibold italic text-gray-800">guarantees</span> delightful discoveries.
				</span>
			),
			link: '',
			doc: '',
			externalLink: 'https://quotes.americanreading.com/products/standard#elementary-wide-reading-libraries'
		},
		{
			id: 'hook-books',
			name: 'Hook Books',
			text: (
				<span>
					Discover handpicked <span className="font-semibold italic text-gray-800">gems</span> designed to captivate even the most hesitant readers. Our collections
					infuse classroom libraries with tales that ignite <span className="font-semibold italic text-gray-800">passion</span> and foster growth.
				</span>
			),
			link: '',
			doc: '',
			externalLink: 'https://quotes.americanreading.com/products/standard#kindergarten-hook-book-collections'
		},
		{
			id: 'thematic-text-sets',
			name: 'Thematic Text Sets',
			text: (
				<span>
					Delve into curated <span className="font-semibold italic text-gray-800">journeys</span> from Social Studies to Science or literary genres. Our sets enrich
					students with meaningful content, <span className="font-semibold italic text-gray-800">amplifying</span> their reading prowess and knowledge.
				</span>
			),
			link: '/themes',
			doc: '',
			externalLink: ''
		},

		{
			id: 'best-books',
			name: 'Best Books',
			text: (
				<span>
					Respected educator <span className="font-semibold italic text-gray-800">Dr. Pedro Noguera</span> has partnered with ARC to create independent reading
					collections <span className="font-semibold italic text-gray-800">hand–selected</span> from the best fiction, nonfiction, and poetry available on the publishing
					market.
				</span>
			),
			link: '',
			doc: '',
			externalLink: 'https://quotes.americanreading.com/products/standard#best-books-by-dr-pedro-noguera'
		},
		{
			id: 'be-the-book',
			name: 'Be the Book',
			text: (
				<span>
					Teaming up with best-selling author and researcher <span className="font-semibold italic text-gray-800">Dr. Jeffrey Wilhelm</span>, ARC delivers classroom
					libraries crafted with care. Dive into 100 titles from <span className="font-semibold italic text-gray-800">250+ publishers</span>, all curated based on Dr.
					Wilhelm's renowned research.
				</span>
			),
			link: '',
			doc: '',
			externalLink: 'https://quotes.americanreading.com/products/standard#be-the-book-collections-by-dr-jeffrey-wilhelm'
		}
	]

	const subNavItems = [
		{ name: 'Wide Reading', link: 'wide-reading' },
		{ name: 'Hook Books', link: 'hook-books' },
		{ name: 'Thematic Text Sets', link: 'thematic-text-sets' },
		{ name: 'Best Books', link: 'best-books' },
		{ name: 'Be the Book', link: 'be-the-book' },
		{ name: 'Journey of a Book', link: 'journey-of-a-book' },
		{ name: 'ARC Press', link: 'arc-press' }
	]

	function goToCta() {
		const input = document.getElementById('get-started-cta-email')
		input?.focus()
	}

	return (
		<Layout pageName="Libraries">
			<SubNav items={subNavItems} />
			<Section className="overflow-hidden pt-10 pb-0">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[60px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[80px] sm:block"></div>
				</div>

				<Row middle className="relative">
					<Col width="w-full md:w-1/2">
						<H1 className="mb-4">
							Perfectly Curated{' '}
							<span className="relative leading-relaxed text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
								Knowledge-Building
							</span>{' '}
							Classroom Libraries
						</H1>
						<P className="mb-8">
							Discover ARC's exclusive library of handpicked texts designed to develop reading skills, enhance vocabulary, and build content knowledge in English and
							Spanish.
						</P>
						<div className="mb-8 flex items-center gap-6">
							<Button onClick={goToCta} size="xl">
								Build Your Library
							</Button>
							{/* <button onClick={() => setModalOpen(true)} className="flex gap-2">
								<span className="text-gray-500">Watch It in Action</span>
								<div className="flex h-6 w-6 items-center justify-center rounded-full border border-ab-100">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</button> */}
						</div>
					</Col>
					<Col width="w-1/2" className="hidden md:block">
						<StaticImage loading="eager" src="../../static/images/libraries/hero.png" alt="girl holding a stack of books over her head" />
					</Col>
				</Row>
			</Section>
			<Section id="collections" margin="mt-20" scrollMarginTop="75px">
				<div className="mb-10">
					<LanguageToggle language={language} onClickEnglish={() => setLanguage('english')} onClickSpanish={() => setLanguage('spanish')} />
				</div>
				<SectionTitle title="Explore Available Library Collections" />
				{collections.map((c, i) => {
					return (
						<div id={c.id} key={c.id} className="flow mb-20 grid grid-cols-1 items-center gap-16 px-4 sm:grid-cols-2">
							<div className={i % 2 ? 'order-1 sm:order-2' : 'order-1'}>
								<img src={`/images/libraries/${c.id}-basket-${language}.png`} alt={`${c.name} basket`} />
							</div>
							<div className={i % 2 ? 'order-1' : 'order-1 sm:order-2'}>
								<span className="mb-3 block text-4xl font-semibold">{c.name}</span>
								<P className="mb-4 max-w-lg lg:leading-relaxed">{c.text}</P>
								{c.externalLink && (
									<a href={c.externalLink} target="_blank">
										<Button>Shop This Collection</Button>
									</a>
								)}
								{c.link && (
									<Link to={c.link}>
										<Button>See All Themes</Button>
									</Link>
								)}
							</div>
						</div>
					)
				})}

				{/* <Row>
					{collections.map((collection) => {
						return (
							<Col key={collection.id} width="w-full md:w-1/2" className="mb-6">
								<CollectionCard collection={collection} />
							</Col>
						)
					})}
				</Row> */}
			</Section>
			<Section margin="mt-20">
				<Row center>
					<Col width="w-full md:w-4/5 xl:w-3/5">
						<JourneyOfABook onClick={goToCta} />
					</Col>
				</Row>
			</Section>
			<Section id="arc-press" margin="mt-20 md:mt-40" scrollMarginTop="75px">
				<div className="flex flex-wrap items-end px-4 pt-20">
					<div className="mb-2 w-full sm:mb-0 sm:w-1/2">
						<H2>
							Illuminate Minds with <br className="hidden sm:block" /> ARC Press Books
						</H2>
					</div>
					<div className="w-full sm:w-1/2">
						<P>
							Ignite young minds with our diverse range of over <span className="font-semibold italic text-gray-700">1,200 books</span> in English and Spanish.
							Designed for early readers, each book blends vivid illustrations with easy-to-follow narratives, making independent reading fun and accessible for all
							ages.
						</P>
					</div>
				</div>
				<div className="flex flex-wrap justify-center px-4 sm:flex-nowrap sm:px-0">
					<StaticImage src="../../static/images/arc-press/mapping-my-world-spread.png" alt="mapping my world cover spread" height={400} />
					<StaticImage src="../../static/images/arc-press/if-the-shoe-fits-spread.png" alt="mapping my world cover spread" height={400} className="-mt-16 sm:mt-0" />
				</div>
			</Section>
			<Section>
				<SectionTitle title="Watch It in Action" />
				<Row>
					<Col width="w-1/2">
						<OverlayVideo title="Inspiring a Passion for Reading" />
						<P size="sm" className="mt-2 text-center">
							Implementing 100 Book Challenge transforms school's reading culture and accelerates student growth.
						</P>
					</Col>
					<Col width="w-1/2">
						<OverlayVideo title="Fostering a Love of Literacy in School and at Home" />
						<P size="sm" className="mt-2 text-center">
							ARC Loves when students and their parents create a community of reading at home.
						</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle overflow={false}>
					<Col width="w-11/12 sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA
							pageName="Libraries Page"
							subHeading="Ready. Set. Read."
							heading="Start Building Your Classroom Libraries"
							underline="Classroom Libraries"
							copy="Choose from dozens of content-rich themes your students will love."
						/>
						<span className="absolute -bottom-6 -left-6 z-[-1] hidden sm:inline">
							<svg width="107" height="134" viewBox="0 0 107 134" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="104.999" cy="132" r="1.66667" transform="rotate(180 104.999 132)" fill="#d1d5db" />
								<circle cx="104.999" cy="117.333" r="1.66667" transform="rotate(180 104.999 117.333)" fill="#d1d5db" />
								<circle cx="104.999" cy="102.667" r="1.66667" transform="rotate(180 104.999 102.667)" fill="#d1d5db" />
								<circle cx="104.999" cy="88" r="1.66667" transform="rotate(180 104.999 88)" fill="#d1d5db" />
								<circle cx="104.999" cy="73.3333" r="1.66667" transform="rotate(180 104.999 73.3333)" fill="#d1d5db" />
								<circle cx="104.999" cy="45" r="1.66667" transform="rotate(180 104.999 45)" fill="#d1d5db" />
								<circle cx="104.999" cy="16" r="1.66667" transform="rotate(180 104.999 16)" fill="#d1d5db" />
								<circle cx="104.999" cy="59" r="1.66667" transform="rotate(180 104.999 59)" fill="#d1d5db" />
								<circle cx="104.999" cy="30.6666" r="1.66667" transform="rotate(180 104.999 30.6666)" fill="#d1d5db" />
								<circle cx="104.999" cy="1.66665" r="1.66667" transform="rotate(180 104.999 1.66665)" fill="#d1d5db" />
								<circle cx="90.3333" cy="132" r="1.66667" transform="rotate(180 90.3333 132)" fill="#d1d5db" />
								<circle cx="90.3333" cy="117.333" r="1.66667" transform="rotate(180 90.3333 117.333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="102.667" r="1.66667" transform="rotate(180 90.3333 102.667)" fill="#d1d5db" />
								<circle cx="90.3333" cy="88" r="1.66667" transform="rotate(180 90.3333 88)" fill="#d1d5db" />
								<circle cx="90.3333" cy="73.3333" r="1.66667" transform="rotate(180 90.3333 73.3333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="45" r="1.66667" transform="rotate(180 90.3333 45)" fill="#d1d5db" />
								<circle cx="90.3333" cy="16" r="1.66667" transform="rotate(180 90.3333 16)" fill="#d1d5db" />
								<circle cx="90.3333" cy="59" r="1.66667" transform="rotate(180 90.3333 59)" fill="#d1d5db" />
								<circle cx="90.3333" cy="30.6666" r="1.66667" transform="rotate(180 90.3333 30.6666)" fill="#d1d5db" />
								<circle cx="90.3333" cy="1.66665" r="1.66667" transform="rotate(180 90.3333 1.66665)" fill="#d1d5db" />
								<circle cx="75.6654" cy="132" r="1.66667" transform="rotate(180 75.6654 132)" fill="#d1d5db" />
								<circle cx="31.9993" cy="132" r="1.66667" transform="rotate(180 31.9993 132)" fill="#d1d5db" />
								<circle cx="75.6654" cy="117.333" r="1.66667" transform="rotate(180 75.6654 117.333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="102.667" r="1.66667" transform="rotate(180 75.6654 102.667)" fill="#d1d5db" />
								<circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#d1d5db" />
								<circle cx="75.6654" cy="88" r="1.66667" transform="rotate(180 75.6654 88)" fill="#d1d5db" />
								<circle cx="31.9993" cy="88" r="1.66667" transform="rotate(180 31.9993 88)" fill="#d1d5db" />
								<circle cx="75.6654" cy="73.3333" r="1.66667" transform="rotate(180 75.6654 73.3333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="45" r="1.66667" transform="rotate(180 75.6654 45)" fill="#d1d5db" />
								<circle cx="31.9993" cy="45" r="1.66667" transform="rotate(180 31.9993 45)" fill="#d1d5db" />
								<circle cx="75.6654" cy="16" r="1.66667" transform="rotate(180 75.6654 16)" fill="#d1d5db" />
								<circle cx="31.9993" cy="16" r="1.66667" transform="rotate(180 31.9993 16)" fill="#d1d5db" />
								<circle cx="75.6654" cy="59" r="1.66667" transform="rotate(180 75.6654 59)" fill="#d1d5db" />
								<circle cx="31.9993" cy="59" r="1.66667" transform="rotate(180 31.9993 59)" fill="#d1d5db" />
								<circle cx="75.6654" cy="30.6666" r="1.66667" transform="rotate(180 75.6654 30.6666)" fill="#d1d5db" />
								<circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#d1d5db" />
								<circle cx="75.6654" cy="1.66665" r="1.66667" transform="rotate(180 75.6654 1.66665)" fill="#d1d5db" />
								<circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#d1d5db" />
								<circle cx="60.9993" cy="132" r="1.66667" transform="rotate(180 60.9993 132)" fill="#d1d5db" />
								<circle cx="17.3333" cy="132" r="1.66667" transform="rotate(180 17.3333 132)" fill="#d1d5db" />
								<circle cx="60.9993" cy="117.333" r="1.66667" transform="rotate(180 60.9993 117.333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="102.667" r="1.66667" transform="rotate(180 60.9993 102.667)" fill="#d1d5db" />
								<circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#d1d5db" />
								<circle cx="60.9993" cy="88" r="1.66667" transform="rotate(180 60.9993 88)" fill="#d1d5db" />
								<circle cx="17.3333" cy="88" r="1.66667" transform="rotate(180 17.3333 88)" fill="#d1d5db" />
								<circle cx="60.9993" cy="73.3333" r="1.66667" transform="rotate(180 60.9993 73.3333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="45" r="1.66667" transform="rotate(180 60.9993 45)" fill="#d1d5db" />
								<circle cx="17.3333" cy="45" r="1.66667" transform="rotate(180 17.3333 45)" fill="#d1d5db" />
								<circle cx="60.9993" cy="16" r="1.66667" transform="rotate(180 60.9993 16)" fill="#d1d5db" />
								<circle cx="17.3333" cy="16" r="1.66667" transform="rotate(180 17.3333 16)" fill="#d1d5db" />
								<circle cx="60.9993" cy="59" r="1.66667" transform="rotate(180 60.9993 59)" fill="#d1d5db" />
								<circle cx="17.3333" cy="59" r="1.66667" transform="rotate(180 17.3333 59)" fill="#d1d5db" />
								<circle cx="60.9993" cy="30.6666" r="1.66667" transform="rotate(180 60.9993 30.6666)" fill="#d1d5db" />
								<circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#d1d5db" />
								<circle cx="60.9993" cy="1.66665" r="1.66667" transform="rotate(180 60.9993 1.66665)" fill="#d1d5db" />
								<circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#d1d5db" />
								<circle cx="46.3333" cy="132" r="1.66667" transform="rotate(180 46.3333 132)" fill="#d1d5db" />
								<circle cx="2.66536" cy="132" r="1.66667" transform="rotate(180 2.66536 132)" fill="#d1d5db" />
								<circle cx="46.3333" cy="117.333" r="1.66667" transform="rotate(180 46.3333 117.333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="102.667" r="1.66667" transform="rotate(180 46.3333 102.667)" fill="#d1d5db" />
								<circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#d1d5db" />
								<circle cx="46.3333" cy="88" r="1.66667" transform="rotate(180 46.3333 88)" fill="#d1d5db" />
								<circle cx="2.66536" cy="88" r="1.66667" transform="rotate(180 2.66536 88)" fill="#d1d5db" />
								<circle cx="46.3333" cy="73.3333" r="1.66667" transform="rotate(180 46.3333 73.3333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="45" r="1.66667" transform="rotate(180 46.3333 45)" fill="#d1d5db" />
								<circle cx="2.66536" cy="45" r="1.66667" transform="rotate(180 2.66536 45)" fill="#d1d5db" />
								<circle cx="46.3333" cy="16" r="1.66667" transform="rotate(180 46.3333 16)" fill="#d1d5db" />
								<circle cx="2.66536" cy="16" r="1.66667" transform="rotate(180 2.66536 16)" fill="#d1d5db" />
								<circle cx="46.3333" cy="59" r="1.66667" transform="rotate(180 46.3333 59)" fill="#d1d5db" />
								<circle cx="2.66536" cy="59" r="1.66667" transform="rotate(180 2.66536 59)" fill="#d1d5db" />
								<circle cx="46.3333" cy="30.6666" r="1.66667" transform="rotate(180 46.3333 30.6666)" fill="#d1d5db" />
								<circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#d1d5db" />
								<circle cx="46.3333" cy="1.66665" r="1.66667" transform="rotate(180 46.3333 1.66665)" fill="#d1d5db" />
								<circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#d1d5db" />
							</svg>
						</span>
						<span className="absolute -top-10 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface CollectionCard {
	collection: {
		id: string
		name: string
		text: string
		image: string
		link?: string
		doc?: string
		externalLink?: string
	}
}

function CollectionCard({ collection }: CollectionCard) {
	return (
		<div id={collection.id} className="h-full rounded-b-lg border shadow">
			<div className="bg-slate-100 p-8">
				<img src={collection.image} alt="hook books" />
			</div>
			<div className="p-6">
				<span className="mb-3 block text-3xl font-semibold">{collection.name}</span>
				<P size="md" className="mb-3">
					{collection.text}
				</P>
				{collection.link && (
					<Link to={collection.link}>
						<Button>Learn More</Button>
					</Link>
				)}
				{collection.doc && (
					<Resource title={collection.doc} linkOnly>
						<Button>Learn More</Button>
					</Resource>
				)}
				{collection.externalLink && (
					<a href={collection.externalLink} target="_blank">
						<Button>Learn More</Button>
					</a>
				)}
			</div>
		</div>
	)
}
