import { Button } from '@arckit/ui'
import { H3 } from 'components/typography/Headings'
import React from 'react'

interface Props {
	onClick: () => void
}

export default function JourneyOfABook({ onClick }: Props) {
	return (
		<div className="relative rounded-lg bg-ab-100">
			<div className="absolute -left-24 -top-1/2 h-[300px] w-[300px] rounded-full bg-white/40 opacity-40"></div>
			<div className="absolute -right-10 -bottom-1/2 h-[220px] w-[220px] rounded-full bg-white/40 opacity-40"></div>
			<div className="flex flex-wrap items-center justify-between py-16 pl-6 pr-10 sm:flex-nowrap">
				<div className="mb-6 sm:mb-0">
					<span className="mb-1 block text-xl font-semibold text-white md:text-2xl">Follow the Journey.</span>
					<H3 className="max-w-md text-white">Interested in how we curate collections?</H3>
				</div>
				<div className="flex-none">
					<Button onClick={onClick} size="xl" className="bg-core-y font-semibold text-gray-700 hover:bg-core-or">
						Journey of a Book
					</Button>
				</div>
			</div>
		</div>
	)
}
